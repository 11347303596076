export default function clearMessagesForApp(el, allSize) {
  delete el.vCards;
  el.author = { user: el._data?.author?.user };
  el.notifyName = el?._data?.notifyName;
  if (el.hasMedia) {
    if (+el._data.size > 0) {
      el.size = el._data.size;
      allSize += +el._data.size;
    }
  }
  delete el._data;
  return [el, allSize];
}
