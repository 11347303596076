import { configureStore } from "@reduxjs/toolkit";
import chatReducer from "./reducers/chat";
import userReducer from "./reducers/user";
import modalReducer from "./reducers/modal";

export const store = configureStore({
  reducer: {
    chat: chatReducer,
    user: userReducer,
    modal: modalReducer,
  },
});
