import Chats from "./pages/Chats";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Profile from "./pages/Profile";
import { useState } from "react";
import Game from "./pages/Game";
import PrivateRoute from "./pages/PrivateRoute";

function App() {
  const [showApp, setShowApp] = useState(0);
  return (
    <>
      {/* {showApp === 3 ? ( */}
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Chats />
              </PrivateRoute>
            }
          />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
      {/* ) : (
        <Game setShowApp={setShowApp} />
      )} */}
    </>
  );
}

export default App;
