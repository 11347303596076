import { useDispatch, useSelector } from "react-redux";
import ChatsApi from "../api/ChatsApi";
import DatabaseAPI from "../api/DatabaseAPI";
import Spinner from "./blocks/Spinner";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addAccount, setSession } from "../store/reducers/user";
import { store } from "../store/store";
import { closeModal } from "../store/reducers/modal";
import { motion } from "framer-motion";

export default function ModalAccount({ onLoad }) {
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.user.userData);
  const [showSpinner, setShowSpinner] = useState(false);
  const [qrCode, setQrCode] = useState(false);
  const session = useSelector((state) => state.user.session);
  const [account, setAccount] = useState();
  const [phone, setPhone] = useState("");
  const [confirm, setConfirm] = useState(false);

  const handleClickConfirm = () => {
    setQrCode();
    setShowSpinner(true);
    dispatch(addAccount(account));
    toast.success("Аккаунт успешно добавлен! Теперь скачиваем ваши чаты 🤩");

    DatabaseAPI.updateUser(dataUser.username, {
      accounts: store.getState().user.userData.accounts,
      chats: store.getState().user.userData.chats,
    }).then(() => {
      onLoad();
      dispatch(closeModal("modalAccount"));
      setShowSpinner(false);
    });
  };

  const handleClickContinue = () => {
    if (phone.length === 0 || !phone.match(/7\d{10}/)) {
      toast.error("Телефон должен быть в формате 79876543210");
    } else {
      setShowSpinner(true);
      ChatsApi.startSession(dataUser.username, phone)
        .catch(() =>
          toast.error("Что-то пошло не так :( Попробуйте повторить позже!")
        )
        .then(() => {
          const timeoutFunc = new Promise((resolve) =>
            setTimeout(() => {
              ChatsApi.getQr(dataUser.username, phone)
                .then((el) => {
                  if (el.status === 422) {
                    throw Error;
                  }
                  return el.json();
                })
                .catch(() =>
                  toast.error(
                    "Что-то пошло не так попробуйте еще раз через минуту  🥲"
                  )
                )
                .then((el) => setQrCode(el?.img));
              setShowSpinner(false);
              setConfirm(true);
              setAccount(phone);
              if (!session) {
                dispatch(setSession(phone));
              }
              resolve();
            }, 15000)
          );
          toast.promise(timeoutFunc, {
            pending: "Добавляется аккаунт в систему. Подождите 15 секунд!",
            success: "Все готово 👌. Можете сканировать QR код",
            error:
              "Произошла ошибка 🤯 Проверьте правильные ли номер телефона и повторите еще раз!",
          });
        });
    }
  };

  const handleClickCancel = () => {
    setQrCode();
    setShowSpinner(false);
    dispatch(closeModal("modalAccount"));
  };
  return (
    <motion.div
      className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-[rgba(0,0,0,.7)]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="relative my-6 mx-auto w-[90%] md:w-[50%]">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-secondarylight outline-none focus:outline-none">
          <div className="flex items-start justify-between p-5 border-b border-solid border-[#2a2a2a] rounded-t ">
            <h3 className="md:text-3xl font-bold text-white m-auto text-center md:text-left text-[1.65rem]">
              Добавить новый аккаунт
            </h3>
          </div>
          {showSpinner ? (
            <Spinner />
          ) : (
            <div className="relative p-2 ml-2 flex-auto text-center">
              {!qrCode ? (
                <>
                  <label className="block text-white text-md font-bold mb-1 ">
                    Номер телефона
                  </label>
                  <input
                    className=" bg-[#cdcdcd] text-black rounded-[5px]  font-bold  px-[.5rem] py-[.2rem] h-[35px]  relative  block w-[250px] mx-auto my-[1rem] text-[14px] outline-none "
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                </>
              ) : (
                <>
                  <img
                    src={qrCode}
                    alt="File for send"
                    className="w-[40%]  mx-auto my-3"
                  />
                  <p className="my-5 px-[3rem] font-bold text-white text-center text-md">
                    Пожалуйста отсканируйте QR код, и дождитесь статуса активно
                    на вашем телефоне. Это важно для корректной загрузки чатов
                    😉
                  </p>
                </>
              )}
            </div>
          )}
          <div className="flex items-center justify-end py-[1rem] px-2 border-t border-solid border-[#2a2a2a] rounded-b">
            <button
              className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
              type="button"
              onClick={handleClickCancel}
            >
              Отменить
            </button>
            {!confirm ? (
              <button
                className="text-white bg-primary  font-bold uppercase text-sm px-6 py-3 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={handleClickContinue}
              >
                Далее
              </button>
            ) : (
              <button
                className="text-white bg-primary  font-bold uppercase text-sm px-6 py-3 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={handleClickConfirm}
              >
                Добавить
              </button>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}
