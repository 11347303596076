import { useState } from "react";
import ChatsApi from "../../api/ChatsApi";
import DatabaseAPI from "../../api/DatabaseAPI";
import { useDispatch, useSelector } from "react-redux";
import { setChats } from "../../store/reducers/user";
import { useNavigate } from "react-router-dom";

export const TooltipChats = ({ children, chatId }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataUser = useSelector((state) => state.user.userData);
  const session = useSelector((state) => state.user.session);
  const chats = dataUser?.chats?.[session];
  return (
    <div
      className="relative flex flex-col  group  z-50"
      onClick={() => {
        setShow(!show);
      }}
    >
      {children}

      <div
        className={`absolute whitespace-nowrap top-full flex flex-col  items-center right-[-1rem]  ${
          !show ? "hidden" : ""
        }`}
      >
        <div className="w-3 h-3 -mb-[1.5rem] rotate-45 bg-secondarylight mt-[.5rem] ml-[11.5rem]" />
        <span
          className={`relative z-10 p-[.9rem] text-[.85rem] text-left leading-none text-white whitespace-no-wrap bg-secondarylight shadow-lg rounded-md flex gap-3 mt-[1rem]`}
        >
          <div className="flex flex-col gap-3  font-bold ">
            <span
              className="text-red-500 cursor-pointer"
              onClick={() => {
                ChatsApi.deleteMessages(dataUser.username, session, chatId);
              }}
            >
              Удалить все сообщения в чате
            </span>
            <span
              className="text-red-500 cursor-pointer"
              onClick={() => {
                ChatsApi.deleteChat(dataUser.username, session, chatId);
              }}
            >
              Удалить чат
            </span>
            <span
              className="text-red-500 cursor-pointer"
              onClick={() => {
                ChatsApi.deleteChat(dataUser.username, session, chatId).then(
                  () => {
                    const newChats = chats.filter(
                      (el) => el.id._serialized !== chatId
                    );
                    dispatch(setChats(newChats, session));
                    //Удалить определенный чат
                    DatabaseAPI.deleteChat(
                      dataUser.username,
                      chatId,
                      session
                    ).then(() =>
                      navigate("/", {
                        state: {
                          session,
                          id: null,
                          name: null,
                          img: "",
                        },
                      })
                    );
                  }
                );
              }}
            >
              Удалить все сообщения и чат в приложении
            </span>
          </div>
        </span>
      </div>
    </div>
  );
};
