import TooltipVoice from "./TooltipVoice";
import { Tooltip } from "./Tooltip";
import {
  FaMicrophone,
  FaFile,
  FaArrowCircleUp,
  FaSmile,
  FaWindowClose,
  FaReply,
  FaTrash,
} from "react-icons/fa";
import ChatsApi from "../../api/ChatsApi";
import { forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "../Modal";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setReplyMessage } from "../../store/reducers/chat";

const SendMessageBox = forwardRef(({ focusOn, currentChat }, ref) => {
  const [text, setText] = useState("");

  const [file, setFile] = useState("");
  const [audioUrl, setAudioUrl] = useState(null);
  const { modal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const session = useSelector((state) => state.user.session);
  const replyMessage = useSelector((state) => state.chat.replyMessage);
  const { username } = useSelector((state) => state.user.userData);

  const sendMessage = async (text, type, data) => {
    await ChatsApi.sendSeen(username, currentChat, session);
    await ChatsApi.startTyping(username, currentChat, session);
    setTimeout(async () => {
      await ChatsApi.stopTyping(username, currentChat, session);
      if (type === "img") {
        toast.promise(ChatsApi.sendImage(username, data), {
          pending: "Ваш файл отправляется... 🤘",
          error:
            "Ваш файл не получилось отправить, может он слишком большой или попробуйте позже 🥲",
          success: "Файл успешно отправлен 😎",
        });
      } else if (type === "voice") {
        ChatsApi.sendVoice(username, text, currentChat, session).then(() =>
          setAudioUrl(null)
        );
      } else {
        if (!replyMessage) {
          ChatsApi.sendText(username, text, currentChat, session);
        } else {
          ChatsApi.replyTo(username, text, currentChat, session, replyMessage);
          dispatch(setReplyMessage(""));
        }
      }
    }, 1000);
    setText("");
    focusOn();
  };
  return (
    <>
      <div
        className={` sticky bg-inherit pt-[1rem] md:p-0 bottom-0 w-[100%] h-[8vh] ${
          replyMessage ? "pb-[7rem]" : "pb-[4rem]"
        } `}
      >
        {replyMessage && (
          <div className="w-[95%] md:w-[90%] flex bg-[#b5b5b566] rounded-t-xl m-auto py-2 px-3 justify-between items-center">
            <div className="flex justify-center items-center">
              <FaReply className="w-[30px] mr-3" />
              <span className="text-md">
                {(replyMessage?.body ?? replyMessage?.payload?.body).length > 30
                  ? (replyMessage?.body ?? replyMessage?.payload?.body).slice(
                      0,
                      30
                    ) + "..."
                  : replyMessage?.body ?? replyMessage?.payload?.body}
              </span>
            </div>
            <FaWindowClose
              className="w-[50px]"
              onClick={() => dispatch(setReplyMessage())}
            />
          </div>
        )}
        <div className=" flex flex-wrap items-stretch m-auto w-[95%] md:w-[90%]">
          {audioUrl && (
            <div
              className={`
        bg-primary text-white    rounded-l-xl
        py-[.5rem] h-[45px] 
        relative  -mr-0.5 block w-[1px] min-w-0 flex-auto  outline-none`}
            >
              <audio controls src={audioUrl.url} className="mainaudio" />
            </div>
          )}
          <input
            type="text"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendMessage(text);
              }
            }}
            ref={ref}
            className={`
bg-secondarylight text-white    ${
              replyMessage ? "rounded-bl-xl" : "rounded-l-xl"
            } ${audioUrl && "hidden"}
p-2 px-4 h-[45px] 
relative  -mr-0.5 block w-[1px] min-w-0 flex-auto  outline-none `}
            placeholder="Ваше сообщение"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <Tooltip setText={setText} emoji={true}>
            <button
              className={`bg-secondarylight  text-white p-1 text-xs  z-[2] inline-block  rounded-none font-bold uppercase leading-normal w-[30px] h-[45px] ${
                audioUrl && "hidden"
              }`}
              type="button"
            >
              <FaSmile className="text-white w-[18px] h-[18px] m-auto" />
            </button>
          </Tooltip>
          <TooltipVoice setAudioUrl={setAudioUrl} audioUrl={audioUrl}>
            <FaMicrophone className="text-white w-[18px] h-[18px] m-auto" />
          </TooltipVoice>
          {audioUrl && (
            <div
              className="bg-secondarylight  text-white px-2.5 z-[2] inline-block  rounded-none "
              onClick={() => setAudioUrl(null)}
            >
              <button
                className={` p-1 text-xs  inline-block  rounded-none font-bold uppercase leading-normal w-[30px] h-[45px]
          `}
                type="button"
              >
                <FaTrash className="text-white w-[18px] h-[18px] m-auto" />
              </button>
            </div>
          )}
          <Tooltip setFile={setFile}>
            <button
              className={`bg-secondarylight  text-white p-1 text-xs  z-[2] inline-block  rounded-none font-bold uppercase leading-normal w-[40px] h-[45px]   ${
                audioUrl && "hidden"
              }`}
              type="button"
            >
              <FaFile className="text-white w-[18px] h-[18px] m-auto" />
            </button>
          </Tooltip>
          <button
            class={`bg-primary  p-1 text-xs z-[2] inline-block   ${
              replyMessage ? "rounded-br-xl" : "rounded-r-xl"
            } w-[55px] h-[45px] text-white font-bold uppercase`}
            type="button"
            onClick={() => {
              if (audioUrl) {
                sendMessage(audioUrl.encoded, "voice");
              } else {
                if (text === "") {
                  return;
                }
                sendMessage(text);
              }
            }}
          >
            <FaArrowCircleUp className="text-white w-[25px] h-[25px] m-auto" />
          </button>
        </div>
      </div>
      {modal && <Modal text={text} sendMessage={sendMessage} file={file} />}
    </>
  );
});

export default SendMessageBox;
