import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { TooltipMessage } from "./TooltipMessage";
import Message from "./Message";
import { formatDate } from "../../utils/utils";
import useInfinityScroll from "../../hooks/useInfinityScroll";
import { useLocation } from "react-router-dom";
import { setMessages, setShowSpinnerMessages } from "../../store/reducers/chat";
import { forwardRef, useEffect, useState } from "react";
import DatabaseAPI from "../../api/DatabaseAPI";
import Spinner from "./Spinner";
import { useDispatch } from "react-redux";

const MessagesGroup = forwardRef(({ focusOn }, ref) => {
  const dispatch = useDispatch();
  const [messagesLastIndex, setMessagesLastIndex] = useState(0);
  const userDataRedux = useSelector((state) => state.user.userData);
  const session = useSelector((state) => state.user.session);
  const showSpinnerMessages = useSelector(
    (state) => state.chat.showSpinnerMessages
  );
  const { state } = useLocation();
  const loadMore = (chatId = state?.id, isInitial) => {
    setMessagesLastIndex((prev) => {
      //Reseting value of prev when changing chats
      if (isInitial) {
        const chatIndex = userDataRedux.chats[
          session === "" ? userDataRedux.accounts[0] : session
        ].findIndex((el) => el.id._serialized === chatId);
        dispatch(
          setMessages(
            userDataRedux.chats[
              session === "" ? userDataRedux.accounts[0] : session
            ]?.[chatIndex]?.messages?.toReversed()
          )
        );
        ref.current.scrollTop = 0;
        dispatch(setShowSpinnerMessages(false));

        return 0;
      }

      DatabaseAPI.getMoreMessages(
        userDataRedux.username,
        session === "" ? userDataRedux.accounts[0] : session,
        chatId,
        prev
      )
        .then((el) => el.json())
        .then((el) => {
          dispatch(setMessages(el.toReversed()));
        });

      return prev + 19;
    });
  };
  const [lastMessageRef] = useInfinityScroll(loadMore);
  const replyMessage = useSelector((state) => state.chat.replyMessage);
  const messages = useSelector((state) => state.chat.messages);
  useEffect(() => {
    if (state?.id && userDataRedux?.chats) {
      loadMore(state.id, true);
    }
  }, [state]);

  return (
    <div
      className={`messagesRef bg-inherit w-[100%] flex-col-reverse py-3  flex items-start justify-start px-[.25rem] md:px-[2.5rem] overflow-scroll h-[68vh] ${
        replyMessage ? "md:h-[75vh]" : "md:h-[80vh]"
      }  mt-2`}
      ref={ref}
    >
      {showSpinnerMessages ? (
        <Spinner />
      ) : (
        messages?.map((el, index) => {
          const formattedDate1 = formatDate(+(el?.timestamp + "000"));
          const formattedDate2 = formatDate(
            +(messages[index - 1]?.timestamp + "000")
          );

          return (
            <>
              {formattedDate1 !== formattedDate2 && messages[index - 1] && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  className="flex w-[100%] gap-[0.5rem] items-center px-3 my-[1rem]"
                >
                  <div className="bg-primary h-[2px] w-[42%] md:w-[45%]"></div>
                  <span className="text-[14px] font-semibold text-primary md:w-[10%] w-[16%] text-center">
                    {formattedDate2}
                  </span>
                  <div className="bg-primary h-[2px] w-[42%] md:w-[45%]"></div>
                </motion.div>
              )}
              <TooltipMessage
                message={el}
                focusOn={focusOn}
                isGroup={el.from.at(-4) === "g"}
              >
                {index === messagesLastIndex ? (
                  <Message
                    message={el}
                    key={index}
                    ref={lastMessageRef}
                    isGroup={el.from.at(-4) === "g"}
                  />
                ) : (
                  <Message
                    message={el}
                    key={index}
                    isGroup={el.from.at(-4) === "g"}
                  />
                )}
              </TooltipMessage>
            </>
          );
        })
      )}
    </div>
  );
});

export default MessagesGroup;
