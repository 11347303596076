import { useNavigate } from "react-router-dom";
import ChatsApi from "../../api/ChatsApi";
import DatabaseAPI from "../../api/DatabaseAPI";
import { formatDate } from "../../utils/utils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUnreadCount } from "../../store/reducers/user";
import { motion } from "framer-motion";
import { FaUser } from "react-icons/fa";

function Chat({ chat, index, currentChat }) {
  const calcDate = (timestamp) => {
    let h = new Date(+(timestamp + "000")).getHours();
    let m = new Date(+(timestamp + "000")).getMinutes();

    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;

    return h + ":" + m;
  };
  const dataUser = useSelector((state) => state.user.userData);
  const session = useSelector((state) => state.user.session);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const displayLastMessage = () => {
    let lastMessage = "";
    if (chat?.lastMessage?.fromMe) {
      lastMessage += "Вы: ";
    }
    if (
      chat?.lastMessage?.hasMedia &&
      chat?.lastMessage?.media?.mimetype !== "audio/mpeg"
    ) {
      lastMessage += `📎  ${
        chat?.lastMessage?.body !== ""
          ? chat?.lastMessage?.body.slice(0, 20)
          : "Файл"
      }`;
    } else if (chat?.lastMessage?.media?.mimetype === "audio/mpeg") {
      lastMessage += `🎵 Аудио`;
    } else if (chat?.lastMessage?.body?.startsWith("BEGIN")) {
      lastMessage = (
        <div className="flex  items-center gap-2">
          <FaUser className="text-[14px] " fill="rgb(60 156 255)" />
          Контакт
        </div>
      );
    } else {
      lastMessage +=
        chat?.lastMessage?.body?.length > 20
          ? chat?.lastMessage?.body?.slice(0, 20) + "..."
          : chat?.lastMessage?.body;
    }
    if (lastMessage === "" || lastMessage === "Вы: ") {
      return <i>Сообщение удалено</i>;
    }
    return lastMessage;
  };
  const handleClickChat = () => {
    if (chat?.unreadCount > 0) {
      ChatsApi.sendSeen(dataUser.username, chat.id._serialized, session);
      const chatIndex = dataUser.chats[session].findIndex(
        (el) => chat.id._serialized === el.id._serialized
      );
      dispatch(setUnreadCount({ index: chatIndex, count: 0 }));
      //Изменить количество прочитанных
      DatabaseAPI.addMessages(
        dataUser.username,
        false,
        0,
        chat.id._serialized,
        session,
        dataUser.allSize
      ).then((res) => {
        navigate("/", {
          state: {
            id: chat.id._serialized,
            session,
            name: chat.name,
            img: chat.avatar ?? "",
          },
        });
      });
    } else {
      navigate("/", {
        state: {
          session,
          id: chat.id._serialized,
          name: chat.name,
          img: chat.avatar ?? "",
        },
      });
    }
  };
  return (
    <motion.div
      className={`p-[1rem]  
border-[#2a2a2a] w-[100%] rounded-xl flex items-center gap-6 xs:gap-3 cursor-pointer hover:bg-secondarylight ${
        currentChat === chat.id._serialized && "bg-secondarylight"
      }`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onClick={handleClickChat}
    >
      <div className="bg-[#ababab] rounded-full md:w-[41px] md:h-[40px] w-[45px] h-[45px]">
        {chat.avatar && (
          <img
            src={chat.avatar}
            className="rounded-full md:w-[100%] md:h-[40px] h-[45px] w-[45px]"
            alt={chat.name}
          />
        )}
      </div>
      <div className="flex flex-col gap-1 text-[#e9e9e9] text-left w-[52%]">
        <h3 className="text-lg md:text-md">{chat.name}</h3>
        <p className="text-[1rem] md:text-[0.85rem] text-[#777779]">
          {displayLastMessage()}
        </p>
      </div>
      <div className="m-auto flex flex-col gap-[.4rem]">
        <p className="text-[.9rem] md:text-[0.85rem] text-[#777779]">
          {formatDate(+(chat?.lastMessage?.timestamp + "000")) === "Сегодня"
            ? calcDate(chat?.lastMessage?.timestamp)
            : formatDate(+(chat?.lastMessage?.timestamp + "000"))}
        </p>
        {dataUser.chats[session ?? dataUser.accounts[0]][index]?.unreadCount >
          0 && (
          <div className="bg-[#44a0ff] text-white m-auto text-center px-[.6rem] py-[.1rem] ml-[.4rem] rounded-full text-[14px]  font-bold justify-end">
            {
              dataUser.chats[session ?? dataUser.accounts[0]][index]
                ?.unreadCount
            }
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default Chat;
