const API_URL = process.env.REACT_APP_DATABASE_API_URL;
class DatabaseAPI {
  getUser(username) {
    try {
      return fetch(`${API_URL}/users/${username}`);
    } catch (error) {
      console.log("User cannot be found:", error);
    }
  }
  getMoreMessages(username, session, chatId, index) {
    try {
      return fetch(
        `${API_URL}/users/${username}/${session}/${chatId}/${index}`
      );
    } catch (error) {
      console.log("User cannot be found:", error);
    }
  }
  addChat(username, chat, session, allSize) {
    try {
      return fetch(`${API_URL}/chats/add`, {
        method: "put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, chat, session, allSize }),
      });
    } catch (error) {
      console.log("Chat cannot be added:", error);
    }
  }
  addMessages(username, message, isCount, chatId, session, allSize) {
    try {
      return fetch(`${API_URL}/chats/messages`, {
        method: "put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          message,
          isCount,
          chatId,
          session,
          allSize,
        }),
      });
    } catch (error) {
      console.log("Chat cannot be added:", error);
    }
  }
  deleteChat(username, chatId, session) {
    try {
      return fetch(`${API_URL}/chats/delete`, {
        method: "delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, chatId, session }),
      });
    } catch (error) {
      console.log("Chat cannot be deleted:", error);
    }
  }
  createUser(data) {
    return fetch(`${API_URL}/users/signup`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
  signInUser(data) {
    return fetch(`${API_URL}/users/signin`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
  verifyToken() {
    return fetch(`${API_URL}/users/login/verifyToken`);
  }
  logOut() {
    return fetch(`${API_URL}/login/logout`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }
  updateUser(username, data) {
    return fetch(`${API_URL}/users/${username}`, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
  updateChats(username, data, session, allSize) {
    try {
      return fetch(`${API_URL}/chats`, {
        method: "put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, data, session, allSize }),
      });
    } catch (error) {
      console.log("Chat cannot be added:", error);
    }
  }
  addUser(data) {
    return fetch(`${API_URL}/users`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
  changeName(username, chatId, name, session) {
    return fetch(`${API_URL}/chats/changeName`, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, chatId, name, session }),
    });
  }
}

export default new DatabaseAPI();
