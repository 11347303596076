import React, { useEffect, useState } from "react";
import DatabaseAPI from "../api/DatabaseAPI";
import Spinner from "../components/blocks/Spinner";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserData } from "../store/reducers/user";

function PrivateRoute({ children }) {
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    DatabaseAPI.verifyToken()
      .then((el) => el.json())
      .then((el) => {
        if (el) {
          DatabaseAPI.getUser(el.username)
            .then((el) => el.json())
            .then((el2) => {
              if (el2.length > 0) {
                setAuth(el2[0]);
                dispatch(setUserData(el2[0]));
                setLoading(false);
              } else {
                DatabaseAPI.logOut().then(setLoading(false));
              }
            });
        } else {
          setLoading(false);
        }
      });
  }, []);
  return (
    <>
      {loading ? (
        <div className="bg-secondary text-white flex  h-[100vh]">
          <Spinner />{" "}
        </div>
      ) : auth ? (
        children
      ) : (
        <Navigate to={"/sign-in"} />
      )}
    </>
  );
}

export default PrivateRoute;
