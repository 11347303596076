const API_URL = process.env.REACT_APP_API_URL;

class ChatsAPI {
  replyTo(user, message, phone, session, replyMessage) {
    const data = {
      session,
      chatId: `${phone}`,
      text: message,
      reply_to: replyMessage.id,
    };
    return fetch(`${API_URL}/backapi/${user}/reply`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
  sendReaction(user, messageId, reaction, session) {
    return fetch(`${API_URL}/backapi/${user}/reaction`, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        messageId,
        session,
        reaction,
      }),
    });
  }
  getQr(user, session) {
    return fetch(`${API_URL}/backapi/${user}/auth/qr/${session}`);
  }
  getAvatar(user, chatId, session) {
    return fetch(
      `${API_URL}/backapi/${user}/contacts/getAvatar/${chatId}/${session}`
    );
  }
  startSession = (user, phone) => {
    return fetch(`${API_URL}/backapi/${user}/sessions/start`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      // make sure to serialize your JSON body
      body: JSON.stringify({
        name: phone,
        config: {
          proxy: null,
          webhooks: [
            {
              url: `${API_URL}/post/${phone}`,
              events: ["message.any"],
              hmac: null,
              retries: {
                delaySeconds: 2,
                attempts: 15,
              },
              customHeaders: null,
            },
          ],
        },
      }),
    });
  };
  stopSession = (user, phone) => {
    return fetch(`${API_URL}/backapi/${user}/sessions/stop`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      // make sure to serialize your JSON body
      body: JSON.stringify({
        logout: false,
        name: phone,
      }),
    });
  };
  async sendText(user, message, phone, session) {
    try {
      const data = {
        session,
        chatId: `${phone}`,
        text: message,
      };
      await fetch(`${API_URL}/backapi/${user}/sendText`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((response) => {
        return response;
      });
    } catch (error) {
      console.log("Err", error);
    }
  }
  startTyping(user, phone, session) {
    return fetch(`${API_URL}/backapi/${user}/startTyping`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        session,
        chatId: `${phone}`,
      }),
    });
  }
  stopTyping(user, phone, session) {
    return fetch(`${API_URL}/backapi/${user}/stopTyping`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        session,
        chatId: `${phone}`,
      }),
    });
  }
  sendImage(user, data) {
    return fetch(`${API_URL}/backapi/${user}/sendImage`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      //make sure to serialize your JSON body
      body: JSON.stringify(data),
    });
  }
  sendVoice(user, data, currentChat, session) {
    return fetch(`${API_URL}/backapi/${user}/sendVoice`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({
        chatId: currentChat,
        file: {
          mimetype: "audio/ogg; codecs=opus ",
          filename: "voice-message-" + Date.now() + ".mp3",
          data,
        },
        session,
      }),
    });
  }
  sendSeen(user, phone, session) {
    return fetch(`${API_URL}/backapi/${user}/sendSeen`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        session,
        chatId: `${phone}`,
      }),
    });
  }
  getMessages(user, id, limit, session) {
    return fetch(
      `${API_URL}/backapi/${user}/chats/messages/${session}/${limit}/${id}`
    );
  }
  getContacts(user, session) {
    return fetch(`${API_URL}/backapi/${user}/contacts/getContacts/${session}`);
  }
  getChats(user, session) {
    return fetch(`${API_URL}/backapi/${user}/chats/getChats/${session}`);
  }
  deleteMessages(user, session, chatId) {
    return fetch(
      `${API_URL}/backapi/${user}/chats/deleteMessages/${chatId}/${session}`,
      {
        method: "delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  }
  deleteChat(user, session, chatId) {
    return fetch(
      `${API_URL}/backapi/${user}/chats/deleteChat/${chatId}/${session}`,
      {
        method: "delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  }
}

export default new ChatsAPI();
