import { useEffect, useRef, useState } from "react";
import ChatsApi from "../api/ChatsApi";
import { store } from "../store/store";
import clickChat from "../api/controlers/ChatsController";
import { useLocation } from "react-router-dom";
import ModalAccount from "../components/ModalAccount";
import DatabaseAPI from "../api/DatabaseAPI";
import ModalDownload from "../components/ModalDownload";
import ModalChats from "../components/ModalChats";
import ModalReply from "../components/ModalReply";
import { useDispatch, useSelector } from "react-redux";
import {
  setReplyMessage,
  setShowChats,
  setMessages,
  addMessage,
  setShowSpinnerMessages,
} from "../store/reducers/chat";
import {
  setUserData,
  sortChats,
  setSession,
  setMessagesByIndex,
  setAllSize,
  setChats,
  setUnreadCount,
  setWebSocket,
} from "../store/reducers/user";
import Sidebar from "../components/blocks/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { openModal } from "../store/reducers/modal";
import clearMessagesForApp from "../utils/clearMessagesForApp";
import SendMessageBox from "../components/blocks/SendMessageBox";
import ContactHeader from "../components/ContactHeader";
import MessagesGroup from "../components/blocks/MessagesGroup";

function Chats() {
  const inputRef = useRef(null);
  const focusOn = () => inputRef.current?.focus();
  const messagesRef = useRef(null);
  const [showSpinner, setShowSpinner] = useState(true);
  const [currentUser, setCurrentUser] = useState();
  const { state } = useLocation();
  const [currentChat, setCurrentChat] = useState(state?.id ?? "");
  const [newMessage, setNewMessage] = useState();
  const [percentage, setPercentage] = useState(0);
  // Configuring Store Redux
  const dispatch = useDispatch();
  const webSocket = useSelector((state) => state.user.webSocket);
  const showChats = useSelector((state) => state.chat.showChats);
  const replyMessage = useSelector((state) => state.chat.replyMessage);
  const userDataRedux = useSelector((state) => state.user.userData);
  const session = useSelector((state) => state.user.session);
  const { modalChats, modalAccount, modalReply, modalDownload } = useSelector(
    (state) => state.modal
  );
  const chats = userDataRedux?.chats?.[session];

  // Функция получения сообщения
  useEffect(() => {
    const gettingMessage = (message) => {
      if (
        userDataRedux?.allSize === 0 ||
        message?.payload?.ackName === "UNKNOWN" ||
        message?.payload?.from === "status@broadcast"
      ) {
        return;
      }
      let allSize = userDataRedux?.allSize;
      if (message.event === "message.any") {
        const messageFromMe = message.payload.fromMe
          ? message.payload.to
          : message.payload.from;
        [message.payload, allSize] = clearMessagesForApp(
          message.payload,
          allSize
        );
        if (messageFromMe === currentChat) {
          dispatch(addMessage(message.payload));
          const chatIndex = chats.findIndex(
            (el) => el.id._serialized === currentChat
          );
          dispatch(
            setMessagesByIndex({
              index: chatIndex,
              messages: [...chats[chatIndex].messages, message.payload],
            })
          );

          dispatch(setAllSize(allSize));
          //Добавить сообщение у чата
          DatabaseAPI.addMessages(
            userDataRedux.username,
            message.payload,
            !message.payload.fromMe,
            messageFromMe,
            session,
            allSize
          );
        } else {
          if (!chats) {
            return;
          }
          const chatIndex = chats.findIndex(
            (el) => el?.id?._serialized === messageFromMe
          );
          if (chatIndex === -1) {
            const newChat = {
              id: {
                _serialized: messageFromMe,
              },
              name: message.payload.fromMe
                ? messageFromMe.slice(0, -5)
                : message.payload?.notifyName ?? messageFromMe.slice(0, -5),
              isGroup: messageFromMe.at(-5) === "g",
              unreadCount: message.payload.fromMe ? 0 : 1,
              messages: [message.payload],
              lastMessage: {
                ...message.payload,
              },
            };

            dispatch(
              setChats([newChat, ...userDataRedux.chats[session]], session)
            );
            dispatch(setAllSize(allSize));
            // Добавить новый чат в начало
            DatabaseAPI.addChat(
              userDataRedux.username,
              newChat,
              session,
              allSize
            );
          } else {
            // Если сообщение не от меня то добавить количество непрочитанных
            if (!message.payload.fromMe) {
              dispatch(
                setUnreadCount({
                  index: chatIndex,
                  count: chats[chatIndex].unreadCount + 1,
                })
              );
            }
            dispatch(
              setMessagesByIndex({
                index: chatIndex,
                messages: [...chats[chatIndex].messages, message.payload],
              })
            );

            dispatch(setAllSize(allSize));

            // Добавить сообщение и изменить количество прочитанных
            DatabaseAPI.addMessages(
              userDataRedux.username,
              message.payload,
              !message.payload.fromMe,
              messageFromMe,
              session,
              allSize
            );
          }
        }
        dispatch(sortChats());
      }

      messagesRef.current.scrollTop = 0;
    };
    if (newMessage) {
      gettingMessage(newMessage);
    }
  }, [newMessage]);

  // Запуск Вебсокета
  useEffect(() => {
    if (session && !webSocket) {
      dispatch(setWebSocket(clickChat(setNewMessage, session)));
    } else if (session && webSocket) {
      webSocket.close(3333);
      dispatch(setWebSocket(clickChat(setNewMessage, session)));
    }
  }, [session]);

  // Загрузка базы данных
  const onLoad = async (currentSession) => {
    const userDataLoad = store.getState().user.userData;
    //Загрузка информации о пользователе
    setCurrentUser({ pushName: userDataLoad.name });

    //ФУНКЦИЯ ДОБАВЛЕНИЯ ИНФОРМАЦИИ НА ПРИЛОЖЕНИЕ
    const dataToApp = (data, session) => {
      dispatch(setUserData(data));
      if (data.accounts.length !== 0) {
        dispatch(setSession(session));
      }
      dispatch(sortChats());
      setShowSpinner(false);
      if (state?.id) {
        dispatch(
          setMessages(
            data.chats[session]
              .find((el) => el.id._serialized === state?.id)
              ?.messages?.toReversed()
          )
        );
      }

      dispatch(setShowSpinnerMessages(false));
    };
    //Включается спиннер
    dispatch(setShowSpinnerMessages(true));

    //Проверка аккаунтов пользователя
    if (userDataLoad.accounts.length === 0) {
      dispatch(setShowSpinnerMessages(false));
      setShowSpinner(false);
      dispatch(openModal("modalAccount"));
    } else if (
      userDataLoad.accounts.length > 0 &&
      (userDataLoad.chats?.[session]?.length === 0 || !userDataLoad.chats)
    ) {
      let currentSession = session ?? userDataLoad.accounts[0];
      dispatch(setSession(currentSession));

      dispatch(openModal("modalDownload"));

      ChatsApi.getChats(userDataLoad.username, currentSession)
        .then((resp) => resp.json())
        .then(async (res) => {
          const data = {
            ...userDataLoad,
            chats: {
              ...userDataLoad.chats,
              [currentSession]: res?.slice(0, 35),
            },
            chatsCount: 0,
          };
          let allSize = 0;
          // FETCH FUNCTION
          const delay = (ms) => new Promise((res) => setTimeout(res, ms));
          const fetchChat = async (el, index) => {
            if (!el?.id?._serialized) {
              data.chatsCount += 1;
              return;
            }

            await ChatsApi.getMessages(
              userDataLoad.username,
              el?.id?._serialized,
              30,
              currentSession
            )
              .then((res) => res.json())
              .then((res) => {
                data.chats[currentSession][index].messages = res.map((el) => {
                  [el, allSize] = clearMessagesForApp(el, allSize);
                  return el;
                });
                setPercentage((prev) => +prev + 1);
              });
            // Загрузка иконки профиля
            await ChatsApi.getAvatar(
              userDataLoad.username,
              el.id.user,
              currentSession
            )
              .then((el) => el.json())
              .then((el) => {
                data.chats[currentSession][index].avatar =
                  el?.profilePictureURL;
                setPercentage((prev) => +prev + 1);
                data.chatsCount += 1;
                if (data.chatsCount === 35) {
                  data.allSize = allSize;
                  //Ничего не меняем
                  DatabaseAPI.updateUser(userDataLoad.username, {
                    chats: data.chats,
                    allSize: data.allSize,
                    accounts: data.accounts,
                  })
                    .then((res) => res.json())
                    .then(() => {
                      setPercentage(100);
                      dataToApp(data, currentSession);
                    });
                }
              });
          };
          for (let i = 0; i < 35; i++) {
            try {
              await fetchChat(data.chats[currentSession][i], i);
            } catch (error) {
              toast.error(
                "Кое-что не загрузилось",
                JSON.stringify(data.chats[currentSession][i])
              );

              data.chatsCount += 1;

              continue;
            }

            if (i === 10) {
              await delay(3000);
            }
          }
        });
    } else {
      const correctSession = currentSession ?? userDataLoad.accounts[0];

      ChatsApi.getChats(userDataLoad.username, correctSession)
        .then((el) => el.json())
        .then((res) => {
          const newChats = res?.slice(0, 20);
          let countChatsUpdate = 0;
          let countChatsUpdated = [];
          toast("Обновление чатов", {
            toastId: "toastID",
          });
          newChats.forEach((el) => {
            const el2 = userDataLoad.chats?.[correctSession].find(
              (existingChat) =>
                el.id._serialized === existingChat.id._serialized
            );
            let allSize = userDataLoad.allSize;
            if (
              el?.lastMessage?.timestamp > el2?.lastMessage?.timestamp ||
              !el2
            ) {
              countChatsUpdate++;

              ChatsApi.getMessages(
                userDataLoad.username,
                el.id._serialized,
                20,
                correctSession
              )
                .then((el) => el.json())
                .then((messages2) => {
                  messages2.map((el) => {
                    [el, allSize] = clearMessagesForApp(el, allSize);
                    return el;
                  });
                  if (!el2) {
                    el.messages = messages2;
                    dispatch(
                      setChats(
                        [
                          el,
                          ...store.getState().user.userData.chats?.[
                            correctSession
                          ],
                        ],
                        correctSession
                      )
                    );
                    countChatsUpdated.push({
                      chat: el,
                    });
                  } else {
                    const superNew = messages2.slice(
                      messages2.findIndex(
                        (message) =>
                          el2?.lastMessage?.timestamp === message?.timestamp
                      ) + 1
                    );

                    const chatIndex = store
                      .getState()
                      .user.userData.chats?.[correctSession].findIndex(
                        (element) =>
                          element.id._serialized === el2.id._serialized
                      );
                    dispatch(
                      setMessagesByIndex({
                        index: chatIndex,
                        messages: [...el2.messages, ...superNew],
                      })
                    );
                    if (el2?.unreadCount !== el?.unreadCount)
                      dispatch(
                        setUnreadCount({
                          index: chatIndex,
                          count: el.unreadCount,
                        })
                      );
                    countChatsUpdated.push({
                      id: el2.id._serialized,
                      messages: superNew,
                      count: el.unreadCount,
                    });
                  }

                  if (countChatsUpdate === countChatsUpdated.length) {
                    dispatch(sortChats());
                    dispatch(setAllSize(allSize));
                    // Надо все обновленные добавить (изменить сообщения) - (добавить новые чаты)
                    DatabaseAPI.updateChats(
                      userDataLoad.username,
                      countChatsUpdated,
                      correctSession,
                      userDataLoad.allSize
                    );

                    toast.update("toastID", {
                      render: `Обновление ${countChatsUpdated.length} чатов успешно 🎉`,
                      type: "success",
                      isLoading: false,
                    });

                    dataToApp(store.getState().user.userData, correctSession);
                    return;
                  }
                });
            }
          });
          // Closing Toast Notification
          if (countChatsUpdate === 0) {
            toast.update("toastID", {
              render: `Все чаты уже обновлены 🤩`,
              autoClose: 1000,
              type: "success",
              isLoading: false,
            });
          }
        });

      dataToApp(userDataLoad, correctSession);
    }
  };

  // Смена чата
  useEffect(() => {
    const changeState = () => {
      // Меняется чат
      if (state?.id && userDataRedux?.chats) {
        setCurrentChat(state?.id);
        if (window.innerWidth < 768) {
          dispatch(setShowChats(false));
        }
      } else {
        if (window.innerWidth < 768) {
          dispatch(setShowChats(true));
        }
      }
      if (state?.session !== session) {
        dispatch(setSession(state?.session));
        onLoad(state?.session);
      } else if (!session) {
        onLoad();
      }
    };
    changeState();
  }, [state]);

  return (
    <div className="bg-secondary text-white flex h-[90vh] md:h-[100vh]">
      {/* Left Sidebar */}
      <Sidebar
        onLoad={onLoad}
        showSpinner={showSpinner}
        currentUser={currentUser}
        currentChat={currentChat}
      />

      {/* Main Part */}
      <div
        className={`w-[100%] ${
          !showChats ? "block" : "hidden"
        } md:block md:w-[72%] bg-inherit   h-[90vh] md:h-[100vh] transition-all
            `}
      >
        {/* Top Menu Contact Name */}
        <ContactHeader />
        {/* Messages in chat */}
        <MessagesGroup ref={messagesRef} focusOn={focusOn} />
        {/* Input and buttons for send messages */}
        <SendMessageBox
          focusOn={focusOn}
          ref={inputRef}
          currentChat={currentChat}
        />
      </div>
      {/* Modal To Connect New Account */}
      {modalAccount && <ModalAccount onLoad={onLoad} />}
      {/* Modal To Download Chats */}
      {modalDownload && <ModalDownload percentage={percentage} />}
      {/* Modal to show chats actions */}
      {modalChats && <ModalChats />}
      {/* Modal to reply message to another chat */}
      {modalReply && <ModalReply setReplyMessage={setReplyMessage} />}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default Chats;
