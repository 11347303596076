import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {
    username: "",
    chats: [],
    accounts: [],
    allSize: 0,
  },
  session: "",
  webSocket: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    sortChats: (state) => {
      state.userData.chats = {
        ...state.userData.chats,
        [state.session]:
          state.userData.chats[state.session].slice().sort((chat1, chat2) => {
            const chat1time =
              +chat1?.lastMessage?.timestamp ||
              +(chat1?.lastMessage?.payload?.timestamp + "000");
            const chat2time =
              +chat2?.lastMessage?.timestamp ||
              +(chat2?.lastMessage?.payload?.timestamp + "000");

            return chat1time > chat2time ? -1 : 1;
          }) ?? [],
      };
    },
    changeName: (state, action) => {
      const chatIndex = state.userData.chats[state.session].findIndex(
        (el) => el.id._serialized === action.payload.chat
      );
      if (chatIndex === -1) {
        return;
      }

      state.userData.chats[state.session][chatIndex].name = action.payload.name;
    },
    setWebSocket: (state, action) => {
      state.webSocket = action.payload;
    },
    setSession: (state, action) => {
      state.session = action.payload;
    },
    setMessagesRedux: (state, action) => {
      const chatIndex = state.userData.chats[state.session].findIndex(
        (el) => el.id._serialized === action.payload.chat
      );
      state.userData.chats[state.session][chatIndex].messages =
        action.payload.messages;
      state.userData.chats[state.session][chatIndex].lastMessage =
        action.payload.messages.at(-1);
    },
    setChats: {
      reducer: (state, action) => {
        state.userData.chats = { ...state.userData.chats, ...action.payload };
      },
      prepare: (chats, session) => {
        return {
          payload: {
            [session]: chats,
          },
        };
      },
    },
    setAllSize: (state, action) => {
      state.userData.allSize = action.payload;
    },
    setMessagesByIndex: (state, action) => {
      state.userData.chats[state.session][action.payload.index].messages =
        action.payload.messages;
      state.userData.chats[state.session][action.payload.index].lastMessage =
        action.payload.messages.at(-1);
    },
    setUnreadCount: (state, action) => {
      state.userData.chats[state.session][action.payload.index].unreadCount =
        action.payload.count;
    },
    addAccount: (state, action) => {
      state.userData.accounts = [...state.userData.accounts, action.payload];
      state.userData.chats = { ...state.userData.chats, [action.payload]: [] };
    },
  },
});

export const {
  setUserData,
  sortChats,
  setUnreadCount,
  setSession,
  setMessagesRedux,
  setAllSize,
  setChats,
  changeName,
  setWebSocket,
  addAccount,
  setMessagesByIndex,
} = userSlice.actions;

export default userSlice.reducer;
