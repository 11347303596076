import spinner from "../../assets/785-4.gif";
import { motion } from "framer-motion";

function Spinner() {
  return (
    <motion.div
      className="m-auto h-max pb-10 shadow-inner  py-[30%] md:py-[20%]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <img className="m-auto w-[64px]" alt="Spinner" src={spinner} />
    </motion.div>
  );
}

export default Spinner;
