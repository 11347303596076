import { forwardRef } from "react";
import { FaFile, FaUser } from "react-icons/fa";
import { motion } from "framer-motion";
import vcard from "vcard-parser";
const API_URL = "https://unicmessenger.ru";

const Message = forwardRef(({ message, isGroup }, ref) => {
  const Parent = ({ children }) => {
    if (ref) {
      return (
        <motion.div
          ref={ref}
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 1 }}
          // transition={{ duration: 0.4 }}
          className={`mx-3 mb-2 rounded-xl  text-white pr-2 pl-3 min-w-[9%] py-2   flex flex-col gap-1 ${
            !message?.fromMe ? "bg-[#2a2a2e] self-start" : "bg-primary self-end"
          } ${
            isAudio(url)
              ? "w-[80%] md:w-[40%]"
              : " max-w-[70%] md:max-w-[45%] w-fit"
          }`}
        >
          {children}
        </motion.div>
      );
    } else {
      return (
        <motion.div
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 1 }}
          // transition={{ duration: 0.4 }}
          className={`mx-3 mb-2 rounded-xl  text-white pr-2 pl-3 min-w-[9%] py-2   flex flex-col gap-1 ${
            !message?.fromMe ? "bg-[#2a2a2e] self-start" : "bg-primary self-end"
          } ${
            isAudio(url)
              ? "w-[80%] md:w-[40%]"
              : " max-w-[70%] md:max-w-[45%] w-fit"
          }`}
        >
          {children}
        </motion.div>
      );
    }
  };

  const url =
    message?.mediaUrl || message?.media?.url || message?.media?.mimeType;
  let text = message?.body || message?.media?.filename;
  if (!text && !url) {
    text = <i>Сообщение удалено</i>;
  }
  const timestamp =
    +(message?.payload?.timestamp + "000") || +(message?.timestamp + "000");
  const calcDate = (timestamp) => {
    let h = new Date(+timestamp).getHours();
    let m = new Date(+timestamp).getMinutes();

    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;

    return h + ":" + m;
  };
  const isFile = (url) => {
    return url && !isVideo(url) && !isImg(url) && !isAudio(url);
  };
  const isVideo = (url) => {
    return url?.split(".").at(-1) === "mp4";
  };
  const isAudio = (url) => {
    return (
      url?.split(".").at(-1) === "oga" ||
      url?.split(".").at(-1) === "mp3" ||
      url?.split(".").at(-1) === "mpga"
    );
  };
  const isImg = (url) => {
    return (
      url?.split(".").at(-1) === "jpeg" ||
      url?.split(".").at(-1) === "png" ||
      url?.split(".").at(-1) === "hrec" ||
      url?.split(".").at(-1) === "webm" ||
      url?.split(".").at(-1) === "webp" ||
      url?.split(".").at(-1) === "jpg"
    );
  };

  if (typeof text === "string" && text?.startsWith("BEGIN:VCARD")) {
    let vcardInfo = vcard.parse(text);
    text = (
      <a href={`tel:${vcardInfo?.tel[0]?.value}`}>
        <div className="flex  items-center gap-4 justify-between px-2">
          <FaUser className="text-[24px]" />
          {vcardInfo?.fn[0]?.value}
        </div>
      </a>
    );
  }
  return (
    <Parent>
      {isGroup && (
        <span className="text-left font-bold  text-[17px] mr-[2.5rem]">
          {message?.notifyName || message?._data?.notifyName
            ? message?.notifyName ?? message?._data?.notifyName
            : "+" + (message?.author?.user ?? message?._data?.author?.user)}
        </span>
      )}
      {/* Проверка файла на тип если пдф или видео */}
      {isVideo(url) && (
        <video
          controls
          className="max-w-[300px] max-h-[300px]"
          src={API_URL + url.slice(21)}
        />
      )}
      {isImg(url) && (
        <img
          src={API_URL + url.slice(21)}
          alt="Image from user"
          className="max-w-[300px] max-h-[300px]"
        />
      )}
      {isAudio(url) && (
        <audio
          controls
          src={API_URL + url.slice(21)}
          className="mainaudio secondaryaudio "
        />
      )}
      {text && (
        <span
          className={` ${
            !message?.fromMe ? "text-left mr-[2.5rem]" : "text-right mr-[2rem]"
          }  break-words `}
        >
          {isFile(url, message) ? (
            <a
              href={API_URL + url.slice(21)}
              target="_blank"
              className="flex gap-2 cursor-pointer"
            >
              <FaFile
                className={`text-primary cursor-pointer text-[1.4rem] my-auto mr-[.5rem] ${
                  message?.fromMe ? "text-white" : "text-primary w-[10%]"
                }`}
              />
              <span className="w-[80%]">{text}</span>
            </a>
          ) : (
            text
          )}
        </span>
      )}
      <span
        className={`text-right text-[10px]  ${
          isAudio(url)
            ? "mt-[-2.5rem]"
            : url && !text
            ? "mt-[-1.8rem] p-[0.4rem] bg-[#2a2a2e52]"
            : " mt-[-1.1rem]"
        } `}
      >
        {calcDate(timestamp)}
      </span>
    </Parent>
  );
});

export default Message;
