import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TooltipChats } from "../components/blocks/TooltipChats";
import { FaPen, FaEllipsisV, FaCheck, FaArrowLeft } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { changeName } from "../store/reducers/user";
import DatabaseAPI from "../api/DatabaseAPI";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { setMessages, setShowSpinnerMessages } from "../store/reducers/chat";

const ContactHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = useSelector((state) => state.user.session);
  const username = useSelector((state) => state.user.userData.username);
  const [isDisabled, setIsDisabled] = useState(true);
  const { state } = useLocation();
  const [currentName, setCurrentName] = useState();
  useEffect(() => {
    setCurrentName(state?.name);
  }, [state]);
  const handleInputAble = () => {
    setIsDisabled(false);
  };
  const handleClickReturn = () => {
    dispatch(setMessages([]));
    dispatch(setShowSpinnerMessages(true));
    navigate("/", {
      state: {
        session,
        id: null,
        name: null,
        img: "",
      },
    });
  };
  const handleChangeName = () => {
    setIsDisabled(true);
    state.name = currentName;
    dispatch(changeName({ chat: state.id, name: currentName }));
    DatabaseAPI.changeName(username, state.id, currentName, session).then(
      (el) => el.json()
    );
  };
  return (
    <motion.div
      className="sticky top-0  z-50 bg-inherit flex justify-center items-center gap-2 md:block h-[10vh] xs:h-[13vh] border-b-[1px] border-[#2a2a2a]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <div className="flex  items-center py-0 md:py-[.8rem] justify-between w-[100%] px-[1.5rem] md:px-[3rem] ">
        <div className="block  md:hidden" onClick={handleClickReturn}>
          <FaArrowLeft className="w-[25px] h-[25px] m-auto text-white" />
        </div>
        <div className="flex items-center justify-around w-full gap-3 px-4 py-2 md:py-0 md:justify-start md:gap-8">
          <div className="bg-[#ababab]  rounded-full w-[40px] h-[40px] ">
            {state?.img && (
              <img
                src={state?.img}
                className="rounded-full w-[40px] h-[40px]"
                alt={state?.name}
              />
            )}
          </div>
          {!isDisabled ? (
            <>
              <input
                type="text"
                className="font-bold text-white  bg-transparent focus:outline-none  ml-[1.5rem]  text-[1.3rem] w-[60%] md:w-[40%]"
                value={currentName}
                onChange={(e) => setCurrentName(e.target.value)}
              />
              <FaCheck
                className="text-[1.3rem] cursor-pointer ml-[1rem]"
                onClick={handleChangeName}
              />
            </>
          ) : (
            <>
              <h3
                type="text"
                className="font-bold text-white  text-[1.3rem] text-center"
              >
                {currentName}
              </h3>

              <FaPen
                className="text-[1rem] cursor-pointer"
                onClick={handleInputAble}
              />
            </>
          )}
        </div>
        <TooltipChats chatId={state?.id}>
          <FaEllipsisV className="w-[20px] h-[20px] cursor-pointer  text-white" />
        </TooltipChats>
      </div>
    </motion.div>
  );
};

export default ContactHeader;
