import { FaSign, FaUser } from "react-icons/fa";
import Field from "../components/blocks/Field";
import { useNavigate } from "react-router-dom";
import DatabaseAPI from "../api/DatabaseAPI";
import Navbar from "../components/blocks/Navbar";
import { useSelector, useDispatch } from "react-redux";
import { setSession, setUserData } from "../store/reducers/user";

function Profile() {
  const userDataRedux = useSelector((state) => state.user.userData);
  const webSocket = useSelector((state) => state.user.webSocket);
  const dispatch = useDispatch();

  const formData = {
    username: userDataRedux.username,
    name: userDataRedux.name,
  };
  const { username, name } = formData;

  const navigate = useNavigate();

  const logOut = () => {
    DatabaseAPI.logOut()
      .then((el) => el.json())
      .then(() => {
        webSocket.close(3333);
        dispatch(setUserData({}));
        dispatch(setSession(""));

        navigate("/sign-in");
      });
  };
  return (
    <div className="bg-secondary h-[100vh]">
      <Navbar />
      <div className="static rounded-2xl bg-[#2c2e30] pt-6 px-3 pb-10 w-[90%] md:w-[55%] m-auto   shadow-xl shadow-[#00000047] mt-[2.5rem] md:mt-[5rem]">
        <div className="absolute bg-primary py-2  text-sm uppercase font-bold px-4 rounded-md top-[6.75rem] left-[50%] ml-[-68.5px] md:top-[9.25rem]  text-black flex gap-3 ">
          <FaUser className="my-auto text-lg" />
          Профиль
        </div>
        <Field
          icon={<FaSign className="text-primary text-4xl my-auto" />}
          placeholder={"Логин"}
          disabled={true}
          text={username}
        ></Field>
        <Field
          icon={<FaSign className="text-primary text-4xl my-auto" />}
          placeholder={"Имя"}
          disabled={true}
          text={name}
        ></Field>
      </div>
      <div className=" text-center mt-9 font-bold cursor-pointer md:mt-14 ">
        <span className="text-red-500" onClick={logOut}>
          Выйти
        </span>
      </div>
    </div>
  );
}

export default Profile;
