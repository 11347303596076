import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modal: false,
  modalChats: false,
  modalReply: false,
  modalAccount: false,
  modalDownload: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action) => {
      state[action.payload] = true;
    },
    closeModal: (state, action) => {
      state[action.payload] = false;
    },
    toggleModal: (state, action) => {
      state[action.payload] = !state[action.payload];
    },
  },
});

export const { openModal, closeModal, toggleModal } = modalSlice.actions;

export default modalSlice.reducer;
