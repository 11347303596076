import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messages: [],
  showSpinnerMessages: true,
  showChats: true,
  replyMessage: "",
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    addMessage: (state, action) => {
      state.messages.unshift(action.payload);
    },
    setShowChats: (state, action) => {
      state.showChats = action.payload;
    },
    setShowSpinnerMessages: (state, action) => {
      state.showSpinnerMessages = action.payload;
    },
    setReplyMessage: (state, action) => {
      state.replyMessage = action.payload;
    },
  },
});

export const {
  setReplyMessage,
  setShowChats,
  addMessage,
  setMessages,
  setShowSpinnerMessages,
} = chatSlice.actions;

export default chatSlice.reducer;
